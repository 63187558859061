// source: connection-service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ppconnection.AddSlotRequest', null, global);
goog.exportSymbol('proto.ppconnection.Address', null, global);
goog.exportSymbol('proto.ppconnection.Connection', null, global);
goog.exportSymbol('proto.ppconnection.Connection.ConnectionState', null, global);
goog.exportSymbol('proto.ppconnection.Connection.ConnectionType', null, global);
goog.exportSymbol('proto.ppconnection.Connection.ModelType', null, global);
//goog.exportSymbol('proto.ppconnection.hasS11', null, global);
goog.exportSymbol('proto.ppconnection.ConnectionImage', null, global);
goog.exportSymbol('proto.ppconnection.ConnectionUpdateMessage', null, global);
goog.exportSymbol('proto.ppconnection.Connections', null, global);
goog.exportSymbol('proto.ppconnection.CreateConnectionRequest', null, global);
goog.exportSymbol('proto.ppconnection.Device', null, global);
goog.exportSymbol('proto.ppconnection.Empty', null, global);
goog.exportSymbol('proto.ppconnection.GetAddressRequest', null, global);
goog.exportSymbol('proto.ppconnection.GetConnectionsByTransformerRequest', null, global);
goog.exportSymbol('proto.ppconnection.GetConnectionsRequest', null, global);
goog.exportSymbol('proto.ppconnection.GetDeviceBySerialNumberRequest', null, global);
goog.exportSymbol('proto.ppconnection.Identifier', null, global);
goog.exportSymbol('proto.ppconnection.Identifiers', null, global);
goog.exportSymbol('proto.ppconnection.Job', null, global);
goog.exportSymbol('proto.ppconnection.Job.JobState', null, global);
goog.exportSymbol('proto.ppconnection.LatLng', null, global);
goog.exportSymbol('proto.ppconnection.Line', null, global);
goog.exportSymbol('proto.ppconnection.LoRaDetails', null, global);
goog.exportSymbol('proto.ppconnection.Location', null, global);
goog.exportSymbol('proto.ppconnection.Mount', null, global);
goog.exportSymbol('proto.ppconnection.Mount.EnclosureType', null, global);
goog.exportSymbol('proto.ppconnection.Mount.MountType', null, global);
goog.exportSymbol('proto.ppconnection.MultipleConnectionRequest', null, global);
goog.exportSymbol('proto.ppconnection.Response', null, global);
goog.exportSymbol('proto.ppconnection.TestResult', null, global);
goog.exportSymbol('proto.ppconnection.Transformer', null, global);
goog.exportSymbol('proto.ppconnection.TransformerList', null, global);
goog.exportSymbol('proto.ppconnection.UpdateConnectionRequest', null, global);
goog.exportSymbol('proto.ppconnection.UpdateConnectionStateRequest', null, global);
goog.exportSymbol('proto.ppconnection.UpdateDeviceRequest', null, global);
goog.exportSymbol('proto.ppconnection.UpdateJobRequest', null, global);
goog.exportSymbol('proto.ppconnection.UpdateLinesRequest', null, global);
goog.exportSymbol('proto.ppconnection.UpdateMountRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Connections = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ppconnection.Connections.repeatedFields_, null);
};
goog.inherits(proto.ppconnection.Connections, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Connections.displayName = 'proto.ppconnection.Connections';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.MultipleConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.MultipleConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.MultipleConnectionRequest.displayName = 'proto.ppconnection.MultipleConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Connection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ppconnection.Connection.repeatedFields_, null);
};
goog.inherits(proto.ppconnection.Connection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Connection.displayName = 'proto.ppconnection.Connection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Job.displayName = 'proto.ppconnection.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.TestResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.TestResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.TestResult.displayName = 'proto.ppconnection.TestResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Line = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Line, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Line.displayName = 'proto.ppconnection.Line';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Location.displayName = 'proto.ppconnection.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Mount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Mount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Mount.displayName = 'proto.ppconnection.Mount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Device = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Device, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Device.displayName = 'proto.ppconnection.Device';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.LoRaDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.LoRaDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.LoRaDetails.displayName = 'proto.ppconnection.LoRaDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.GetConnectionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.GetConnectionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.GetConnectionsRequest.displayName = 'proto.ppconnection.GetConnectionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.GetConnectionsByTransformerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.GetConnectionsByTransformerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.GetConnectionsByTransformerRequest.displayName = 'proto.ppconnection.GetConnectionsByTransformerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Identifier = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Identifier, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Identifier.displayName = 'proto.ppconnection.Identifier';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Identifiers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ppconnection.Identifiers.repeatedFields_, null);
};
goog.inherits(proto.ppconnection.Identifiers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Identifiers.displayName = 'proto.ppconnection.Identifiers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.CreateConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.CreateConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.CreateConnectionRequest.displayName = 'proto.ppconnection.CreateConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.UpdateConnectionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.UpdateConnectionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.UpdateConnectionRequest.displayName = 'proto.ppconnection.UpdateConnectionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.UpdateMountRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.UpdateMountRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.UpdateMountRequest.displayName = 'proto.ppconnection.UpdateMountRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.UpdateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.UpdateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.UpdateDeviceRequest.displayName = 'proto.ppconnection.UpdateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.UpdateConnectionStateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.UpdateConnectionStateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.UpdateConnectionStateRequest.displayName = 'proto.ppconnection.UpdateConnectionStateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.UpdateJobRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.UpdateJobRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.UpdateJobRequest.displayName = 'proto.ppconnection.UpdateJobRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.UpdateLinesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.UpdateLinesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.UpdateLinesRequest.displayName = 'proto.ppconnection.UpdateLinesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.GetAddressRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.GetAddressRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.GetAddressRequest.displayName = 'proto.ppconnection.GetAddressRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.GetDeviceBySerialNumberRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.GetDeviceBySerialNumberRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.GetDeviceBySerialNumberRequest.displayName = 'proto.ppconnection.GetDeviceBySerialNumberRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Response.displayName = 'proto.ppconnection.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Address = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Address, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Address.displayName = 'proto.ppconnection.Address';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.LatLng = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.LatLng, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.LatLng.displayName = 'proto.ppconnection.LatLng';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Empty.displayName = 'proto.ppconnection.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.TransformerList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ppconnection.TransformerList.repeatedFields_, null);
};
goog.inherits(proto.ppconnection.TransformerList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.TransformerList.displayName = 'proto.ppconnection.TransformerList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.Transformer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.Transformer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.Transformer.displayName = 'proto.ppconnection.Transformer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.ConnectionImage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.ConnectionImage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.ConnectionImage.displayName = 'proto.ppconnection.ConnectionImage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.AddSlotRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.AddSlotRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.AddSlotRequest.displayName = 'proto.ppconnection.AddSlotRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ppconnection.ConnectionUpdateMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ppconnection.ConnectionUpdateMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ppconnection.ConnectionUpdateMessage.displayName = 'proto.ppconnection.ConnectionUpdateMessage';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ppconnection.Connections.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Connections.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Connections.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Connections} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Connections.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionsList: jspb.Message.toObjectList(msg.getConnectionsList(),
    proto.ppconnection.Connection.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Connections}
 */
proto.ppconnection.Connections.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Connections;
  return proto.ppconnection.Connections.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Connections} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Connections}
 */
proto.ppconnection.Connections.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ppconnection.Connection;
      reader.readMessage(value,proto.ppconnection.Connection.deserializeBinaryFromReader);
      msg.addConnections(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Connections.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Connections.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Connections} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Connections.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ppconnection.Connection.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Connection connections = 1;
 * @return {!Array<!proto.ppconnection.Connection>}
 */
proto.ppconnection.Connections.prototype.getConnectionsList = function() {
  return /** @type{!Array<!proto.ppconnection.Connection>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ppconnection.Connection, 1));
};


/**
 * @param {!Array<!proto.ppconnection.Connection>} value
 * @return {!proto.ppconnection.Connections} returns this
*/
proto.ppconnection.Connections.prototype.setConnectionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ppconnection.Connection=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ppconnection.Connection}
 */
proto.ppconnection.Connections.prototype.addConnections = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ppconnection.Connection, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ppconnection.Connections} returns this
 */
proto.ppconnection.Connections.prototype.clearConnectionsList = function() {
  return this.setConnectionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.MultipleConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.MultipleConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.MultipleConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.MultipleConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    connection: (f = msg.getConnection()) && proto.ppconnection.Connection.toObject(includeInstance, f),
    count: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.MultipleConnectionRequest}
 */
proto.ppconnection.MultipleConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.MultipleConnectionRequest;
  return proto.ppconnection.MultipleConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.MultipleConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.MultipleConnectionRequest}
 */
proto.ppconnection.MultipleConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ppconnection.Connection;
      reader.readMessage(value,proto.ppconnection.Connection.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.MultipleConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.MultipleConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.MultipleConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.MultipleConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.ppconnection.Connection.serializeBinaryToWriter
    );
  }
  f = message.getCount();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional Connection connection = 1;
 * @return {?proto.ppconnection.Connection}
 */
proto.ppconnection.MultipleConnectionRequest.prototype.getConnection = function() {
  return /** @type{?proto.ppconnection.Connection} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Connection, 1));
};


/**
 * @param {?proto.ppconnection.Connection|undefined} value
 * @return {!proto.ppconnection.MultipleConnectionRequest} returns this
*/
proto.ppconnection.MultipleConnectionRequest.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.MultipleConnectionRequest} returns this
 */
proto.ppconnection.MultipleConnectionRequest.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.MultipleConnectionRequest.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 count = 2;
 * @return {number}
 */
proto.ppconnection.MultipleConnectionRequest.prototype.getCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.MultipleConnectionRequest} returns this
 */
proto.ppconnection.MultipleConnectionRequest.prototype.setCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ppconnection.Connection.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Connection.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Connection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Connection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Connection.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    connectionstate: jspb.Message.getFieldWithDefault(msg, 2, 0),
    connectiontype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    datecreated: jspb.Message.getFieldWithDefault(msg, 4, ""),
    device: (f = msg.getDevice()) && proto.ppconnection.Device.toObject(includeInstance, f),
    idnumber: jspb.Message.getFieldWithDefault(msg, 6, ""),
    numphases: jspb.Message.getFieldWithDefault(msg, 7, 0),
    line1: jspb.Message.getFieldWithDefault(msg, 8, 0),
    line2: jspb.Message.getFieldWithDefault(msg, 9, 0),
    line3: jspb.Message.getFieldWithDefault(msg, 10, 0),
    location: (f = msg.getLocation()) && proto.ppconnection.Location.toObject(includeInstance, f),
    modeltype: jspb.Message.getFieldWithDefault(msg, 12, 0),
    mount: (f = msg.getMount()) && proto.ppconnection.Mount.toObject(includeInstance, f),
    nickname: jspb.Message.getFieldWithDefault(msg, 14, ""),
    type: jspb.Message.getFieldWithDefault(msg, 15, ""),
    job: (f = msg.getJob()) && proto.ppconnection.Job.toObject(includeInstance, f),
    id: jspb.Message.getFieldWithDefault(msg, 17, ""),
    transformer: jspb.Message.getFieldWithDefault(msg, 18, ""),
    rating: jspb.Message.getFieldWithDefault(msg, 19, 0),
    imagesList: (f = jspb.Message.getRepeatedField(msg, 20)) == null ? undefined : f,
    slotsMap: (f = msg.getSlotsMap()) ? f.toObject(includeInstance, undefined) : [],
    dateinstalled: jspb.Message.getFieldWithDefault(msg, 22, ""),
    switching: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    multicircuit: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    hasS11: jspb.Message.getBooleanFieldWithDefault(msg, 25, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Connection}
 */
proto.ppconnection.Connection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Connection;
  return proto.ppconnection.Connection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Connection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Connection}
 */
proto.ppconnection.Connection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {!proto.ppconnection.Connection.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    case 3:
      var value = /** @type {!proto.ppconnection.Connection.ConnectionType} */ (reader.readEnum());
      msg.setConnectiontype(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 5:
      var value = new proto.ppconnection.Device;
      reader.readMessage(value,proto.ppconnection.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdnumber(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumphases(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine1(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine2(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine3(value);
      break;
    case 11:
      var value = new proto.ppconnection.Location;
      reader.readMessage(value,proto.ppconnection.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 12:
      var value = /** @type {!proto.ppconnection.Connection.ModelType} */ (reader.readEnum());
      msg.setModeltype(value);
      break;
    case 13:
      var value = new proto.ppconnection.Mount;
      reader.readMessage(value,proto.ppconnection.Mount.deserializeBinaryFromReader);
      msg.setMount(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNickname(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 16:
      var value = new proto.ppconnection.Job;
      reader.readMessage(value,proto.ppconnection.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransformer(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRating(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.addImages(value);
      break;
    case 21:
      var value = msg.getSlotsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateinstalled(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSwitching(value);
      break;
    case 24:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setMulticircuit(value);
        break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasS11(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Connection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Connection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Connection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Connection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getConnectiontype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ppconnection.Device.serializeBinaryToWriter
    );
  }
  f = message.getIdnumber();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getNumphases();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLine1();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLine2();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getLine3();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.ppconnection.Location.serializeBinaryToWriter
    );
  }
  f = message.getModeltype();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getMount();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.ppconnection.Mount.serializeBinaryToWriter
    );
  }
  f = message.getNickname();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.ppconnection.Job.serializeBinaryToWriter
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTransformer();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getRating();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getImagesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      20,
      f
    );
  }
  f = message.getSlotsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(21, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getDateinstalled();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSwitching();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getMulticircuit();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getHasS11();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ppconnection.Connection.ConnectionState = {
  NO_CONNECTION_STATE: 0,
  PENDING: 1,
  CONNECTED: 2,
  INACTIVE: 3
};

/**
 * @enum {number}
 */
proto.ppconnection.Connection.ConnectionType = {
  NO_CONNECTION_TYPE: 0,
  ICP: 1,
  ASSET: 2
};

/**
 * @enum {number}
 */
proto.ppconnection.Connection.ModelType = {
  NO_MODEL_TYPE: 0,
  M11: 1,
  M31: 2,
  M31S: 3,
  M31X: 4,
  C11_S11: 5
};

/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional ConnectionState connectionState = 2;
 * @return {!proto.ppconnection.Connection.ConnectionState}
 */
proto.ppconnection.Connection.prototype.getConnectionstate = function() {
  return /** @type {!proto.ppconnection.Connection.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ppconnection.Connection.ConnectionState} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional ConnectionType connectionType = 3;
 * @return {!proto.ppconnection.Connection.ConnectionType}
 */
proto.ppconnection.Connection.prototype.getConnectiontype = function() {
  return /** @type {!proto.ppconnection.Connection.ConnectionType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.ppconnection.Connection.ConnectionType} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setConnectiontype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string dateCreated = 4;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional Device device = 5;
 * @return {?proto.ppconnection.Device}
 */
proto.ppconnection.Connection.prototype.getDevice = function() {
  return /** @type{?proto.ppconnection.Device} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Device, 5));
};


/**
 * @param {?proto.ppconnection.Device|undefined} value
 * @return {!proto.ppconnection.Connection} returns this
*/
proto.ppconnection.Connection.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string IDNumber = 6;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getIdnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setIdnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 numPhases = 7;
 * @return {number}
 */
proto.ppconnection.Connection.prototype.getNumphases = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setNumphases = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 line1 = 8;
 * @return {number}
 */
proto.ppconnection.Connection.prototype.getLine1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setLine1 = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 line2 = 9;
 * @return {number}
 */
proto.ppconnection.Connection.prototype.getLine2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setLine2 = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 line3 = 10;
 * @return {number}
 */
proto.ppconnection.Connection.prototype.getLine3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setLine3 = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Location location = 11;
 * @return {?proto.ppconnection.Location}
 */
proto.ppconnection.Connection.prototype.getLocation = function() {
  return /** @type{?proto.ppconnection.Location} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Location, 11));
};


/**
 * @param {?proto.ppconnection.Location|undefined} value
 * @return {!proto.ppconnection.Connection} returns this
*/
proto.ppconnection.Connection.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional ModelType modelType = 12;
 * @return {!proto.ppconnection.Connection.ModelType}
 */
proto.ppconnection.Connection.prototype.getModeltype = function() {
  return /** @type {!proto.ppconnection.Connection.ModelType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.ppconnection.Connection.ModelType} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional Mount mount = 13;
 * @return {?proto.ppconnection.Mount}
 */
proto.ppconnection.Connection.prototype.getMount = function() {
  return /** @type{?proto.ppconnection.Mount} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Mount, 13));
};


/**
 * @param {?proto.ppconnection.Mount|undefined} value
 * @return {!proto.ppconnection.Connection} returns this
*/
proto.ppconnection.Connection.prototype.setMount = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.clearMount = function() {
  return this.setMount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.hasMount = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string nickName = 14;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getNickname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setNickname = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string type = 15;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional Job job = 16;
 * @return {?proto.ppconnection.Job}
 */
proto.ppconnection.Connection.prototype.getJob = function() {
  return /** @type{?proto.ppconnection.Job} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Job, 16));
};


/**
 * @param {?proto.ppconnection.Job|undefined} value
 * @return {!proto.ppconnection.Connection} returns this
*/
proto.ppconnection.Connection.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.hasJob = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string id = 17;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string transformer = 18;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getTransformer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setTransformer = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 rating = 19;
 * @return {number}
 */
proto.ppconnection.Connection.prototype.getRating = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setRating = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * repeated string images = 20;
 * @return {!Array<string>}
 */
proto.ppconnection.Connection.prototype.getImagesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 20));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setImagesList = function(value) {
  return jspb.Message.setField(this, 20, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.addImages = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 20, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.clearImagesList = function() {
  return this.setImagesList([]);
};


/**
 * map<string, string> slots = 21;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.ppconnection.Connection.prototype.getSlotsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 21, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.clearSlotsMap = function() {
  this.getSlotsMap().clear();
  return this;};


/**
 * optional string dateInstalled = 22;
 * @return {string}
 */
proto.ppconnection.Connection.prototype.getDateinstalled = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setDateinstalled = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional bool switching = 23;
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.getSwitching = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setSwitching = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool multicircuit = 24;
 * @return {boolean}
 */
proto.ppconnection.Connection.prototype.getMulticircuit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setMulticircuit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional bool hasS11 = 25;
 * @return {boolean}
 */
 proto.ppconnection.Connection.prototype.getHasS11 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Connection} returns this
 */
proto.ppconnection.Connection.prototype.setHasS11 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};






if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    comments: jspb.Message.getFieldWithDefault(msg, 2, ""),
    notes: jspb.Message.getFieldWithDefault(msg, 3, ""),
    datecreated: jspb.Message.getFieldWithDefault(msg, 4, ""),
    jobstate: jspb.Message.getFieldWithDefault(msg, 5, 0),
    reasonforfailure: jspb.Message.getFieldWithDefault(msg, 6, ""),
    testresult: (f = msg.getTestresult()) && proto.ppconnection.TestResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Job}
 */
proto.ppconnection.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Job;
  return proto.ppconnection.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Job}
 */
proto.ppconnection.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotes(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatecreated(value);
      break;
    case 5:
      var value = /** @type {!proto.ppconnection.Job.JobState} */ (reader.readEnum());
      msg.setJobstate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReasonforfailure(value);
      break;
    case 7:
      var value = new proto.ppconnection.TestResult;
      reader.readMessage(value,proto.ppconnection.TestResult.deserializeBinaryFromReader);
      msg.setTestresult(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getComments();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNotes();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDatecreated();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getJobstate();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getReasonforfailure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTestresult();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.ppconnection.TestResult.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.ppconnection.Job.JobState = {
  NO_JOB_STATE: 0,
  PENDING: 1,
  CONFIGURED: 2,
  INSTALLED: 3,
  TESTED: 4,
  FAILED: 5
};

/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.ppconnection.Job.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string comments = 2;
 * @return {string}
 */
proto.ppconnection.Job.prototype.getComments = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.setComments = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string notes = 3;
 * @return {string}
 */
proto.ppconnection.Job.prototype.getNotes = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.setNotes = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string dateCreated = 4;
 * @return {string}
 */
proto.ppconnection.Job.prototype.getDatecreated = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.setDatecreated = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional JobState jobState = 5;
 * @return {!proto.ppconnection.Job.JobState}
 */
proto.ppconnection.Job.prototype.getJobstate = function() {
  return /** @type {!proto.ppconnection.Job.JobState} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ppconnection.Job.JobState} value
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.setJobstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string reasonForFailure = 6;
 * @return {string}
 */
proto.ppconnection.Job.prototype.getReasonforfailure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.setReasonforfailure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional TestResult testResult = 7;
 * @return {?proto.ppconnection.TestResult}
 */
proto.ppconnection.Job.prototype.getTestresult = function() {
  return /** @type{?proto.ppconnection.TestResult} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.TestResult, 7));
};


/**
 * @param {?proto.ppconnection.TestResult|undefined} value
 * @return {!proto.ppconnection.Job} returns this
*/
proto.ppconnection.Job.prototype.setTestresult = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Job} returns this
 */
proto.ppconnection.Job.prototype.clearTestresult = function() {
  return this.setTestresult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Job.prototype.hasTestresult = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.TestResult.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.TestResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.TestResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.TestResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    downlinkrssi: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    downlinksnr: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    line1: (f = msg.getLine1()) && proto.ppconnection.Line.toObject(includeInstance, f),
    line2: (f = msg.getLine2()) && proto.ppconnection.Line.toObject(includeInstance, f),
    line3: (f = msg.getLine3()) && proto.ppconnection.Line.toObject(includeInstance, f),
    received: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    software: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    uplinkrssi: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    uplinksnr: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.TestResult}
 */
proto.ppconnection.TestResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.TestResult;
  return proto.ppconnection.TestResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.TestResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.TestResult}
 */
proto.ppconnection.TestResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDownlinkrssi(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDownlinksnr(value);
      break;
    case 3:
      var value = new proto.ppconnection.Line;
      reader.readMessage(value,proto.ppconnection.Line.deserializeBinaryFromReader);
      msg.setLine1(value);
      break;
    case 4:
      var value = new proto.ppconnection.Line;
      reader.readMessage(value,proto.ppconnection.Line.deserializeBinaryFromReader);
      msg.setLine2(value);
      break;
    case 5:
      var value = new proto.ppconnection.Line;
      reader.readMessage(value,proto.ppconnection.Line.deserializeBinaryFromReader);
      msg.setLine3(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReceived(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSoftware(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUplinkrssi(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setUplinksnr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.TestResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.TestResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.TestResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.TestResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDownlinkrssi();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getDownlinksnr();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLine1();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ppconnection.Line.serializeBinaryToWriter
    );
  }
  f = message.getLine2();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ppconnection.Line.serializeBinaryToWriter
    );
  }
  f = message.getLine3();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ppconnection.Line.serializeBinaryToWriter
    );
  }
  f = message.getReceived();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSoftware();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getUplinkrssi();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getUplinksnr();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
};


/**
 * optional float downlinkRSSI = 1;
 * @return {number}
 */
proto.ppconnection.TestResult.prototype.getDownlinkrssi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.setDownlinkrssi = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float downlinkSNR = 2;
 * @return {number}
 */
proto.ppconnection.TestResult.prototype.getDownlinksnr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.setDownlinksnr = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional Line line1 = 3;
 * @return {?proto.ppconnection.Line}
 */
proto.ppconnection.TestResult.prototype.getLine1 = function() {
  return /** @type{?proto.ppconnection.Line} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Line, 3));
};


/**
 * @param {?proto.ppconnection.Line|undefined} value
 * @return {!proto.ppconnection.TestResult} returns this
*/
proto.ppconnection.TestResult.prototype.setLine1 = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.clearLine1 = function() {
  return this.setLine1(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.TestResult.prototype.hasLine1 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Line line2 = 4;
 * @return {?proto.ppconnection.Line}
 */
proto.ppconnection.TestResult.prototype.getLine2 = function() {
  return /** @type{?proto.ppconnection.Line} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Line, 4));
};


/**
 * @param {?proto.ppconnection.Line|undefined} value
 * @return {!proto.ppconnection.TestResult} returns this
*/
proto.ppconnection.TestResult.prototype.setLine2 = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.clearLine2 = function() {
  return this.setLine2(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.TestResult.prototype.hasLine2 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Line line3 = 5;
 * @return {?proto.ppconnection.Line}
 */
proto.ppconnection.TestResult.prototype.getLine3 = function() {
  return /** @type{?proto.ppconnection.Line} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Line, 5));
};


/**
 * @param {?proto.ppconnection.Line|undefined} value
 * @return {!proto.ppconnection.TestResult} returns this
*/
proto.ppconnection.TestResult.prototype.setLine3 = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.clearLine3 = function() {
  return this.setLine3(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.TestResult.prototype.hasLine3 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool received = 6;
 * @return {boolean}
 */
proto.ppconnection.TestResult.prototype.getReceived = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.setReceived = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional float software = 7;
 * @return {number}
 */
proto.ppconnection.TestResult.prototype.getSoftware = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.setSoftware = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float uplinkRSSI = 8;
 * @return {number}
 */
proto.ppconnection.TestResult.prototype.getUplinkrssi = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.setUplinkrssi = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float uplinkSNR = 9;
 * @return {number}
 */
proto.ppconnection.TestResult.prototype.getUplinksnr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.TestResult} returns this
 */
proto.ppconnection.TestResult.prototype.setUplinksnr = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Line.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Line.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Line} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Line.toObject = function(includeInstance, msg) {
  var f, obj = {
    current: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    voltage: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Line}
 */
proto.ppconnection.Line.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Line;
  return proto.ppconnection.Line.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Line} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Line}
 */
proto.ppconnection.Line.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCurrent(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setVoltage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Line.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Line.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Line} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Line.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCurrent();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getVoltage();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float current = 1;
 * @return {number}
 */
proto.ppconnection.Line.prototype.getCurrent = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Line} returns this
 */
proto.ppconnection.Line.prototype.setCurrent = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float voltage = 2;
 * @return {number}
 */
proto.ppconnection.Line.prototype.getVoltage = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Line} returns this
 */
proto.ppconnection.Line.prototype.setVoltage = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    streetaddress1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    streetaddress2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    town: jspb.Message.getFieldWithDefault(msg, 3, ""),
    country: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Location}
 */
proto.ppconnection.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Location;
  return proto.ppconnection.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Location}
 */
proto.ppconnection.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetaddress1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetaddress2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTown(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLng(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreetaddress1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStreetaddress2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTown();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
};


/**
 * optional string streetAddress1 = 1;
 * @return {string}
 */
proto.ppconnection.Location.prototype.getStreetaddress1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Location} returns this
 */
proto.ppconnection.Location.prototype.setStreetaddress1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string streetAddress2 = 2;
 * @return {string}
 */
proto.ppconnection.Location.prototype.getStreetaddress2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Location} returns this
 */
proto.ppconnection.Location.prototype.setStreetaddress2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string town = 3;
 * @return {string}
 */
proto.ppconnection.Location.prototype.getTown = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Location} returns this
 */
proto.ppconnection.Location.prototype.setTown = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string country = 4;
 * @return {string}
 */
proto.ppconnection.Location.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Location} returns this
 */
proto.ppconnection.Location.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional float lng = 5;
 * @return {number}
 */
proto.ppconnection.Location.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Location} returns this
 */
proto.ppconnection.Location.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float lat = 6;
 * @return {number}
 */
proto.ppconnection.Location.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Location} returns this
 */
proto.ppconnection.Location.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Mount.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Mount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Mount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Mount.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    location: (f = msg.getLocation()) && proto.ppconnection.Location.toObject(includeInstance, f),
    mountnumber: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mounttype: jspb.Message.getFieldWithDefault(msg, 4, 0),
    enclosuretype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    enclosureid: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Mount}
 */
proto.ppconnection.Mount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Mount;
  return proto.ppconnection.Mount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Mount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Mount}
 */
proto.ppconnection.Mount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = new proto.ppconnection.Location;
      reader.readMessage(value,proto.ppconnection.Location.deserializeBinaryFromReader);
      msg.setLocation(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMountnumber(value);
      break;
    case 4:
      var value = /** @type {!proto.ppconnection.Mount.MountType} */ (reader.readEnum());
      msg.setMounttype(value);
      break;
    case 5:
      var value = /** @type {!proto.ppconnection.Mount.EnclosureType} */ (reader.readEnum());
      msg.setEnclosuretype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnclosureid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Mount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Mount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Mount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Mount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getLocation();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Location.serializeBinaryToWriter
    );
  }
  f = message.getMountnumber();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMounttype();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getEnclosuretype();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getEnclosureid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.ppconnection.Mount.MountType = {
  NO_MOUNT_TYPE: 0,
  CROSSARM: 1,
  SWITCHBOARD: 2,
  METER_BOX: 3,
  SUBSTATION: 4,
  PILLAR_BOX: 5
};

/**
 * @enum {number}
 */
proto.ppconnection.Mount.EnclosureType = {
  NO_ENCLOSURE_TYPE: 0,
  SINGLE: 1,
  DOUBLE: 2,
  TRIPLE: 3
};

/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.ppconnection.Mount.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Mount} returns this
 */
proto.ppconnection.Mount.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Location location = 2;
 * @return {?proto.ppconnection.Location}
 */
proto.ppconnection.Mount.prototype.getLocation = function() {
  return /** @type{?proto.ppconnection.Location} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Location, 2));
};


/**
 * @param {?proto.ppconnection.Location|undefined} value
 * @return {!proto.ppconnection.Mount} returns this
*/
proto.ppconnection.Mount.prototype.setLocation = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Mount} returns this
 */
proto.ppconnection.Mount.prototype.clearLocation = function() {
  return this.setLocation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Mount.prototype.hasLocation = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string mountNumber = 3;
 * @return {string}
 */
proto.ppconnection.Mount.prototype.getMountnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Mount} returns this
 */
proto.ppconnection.Mount.prototype.setMountnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MountType mountType = 4;
 * @return {!proto.ppconnection.Mount.MountType}
 */
proto.ppconnection.Mount.prototype.getMounttype = function() {
  return /** @type {!proto.ppconnection.Mount.MountType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.ppconnection.Mount.MountType} value
 * @return {!proto.ppconnection.Mount} returns this
 */
proto.ppconnection.Mount.prototype.setMounttype = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional EnclosureType enclosureType = 5;
 * @return {!proto.ppconnection.Mount.EnclosureType}
 */
proto.ppconnection.Mount.prototype.getEnclosuretype = function() {
  return /** @type {!proto.ppconnection.Mount.EnclosureType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.ppconnection.Mount.EnclosureType} value
 * @return {!proto.ppconnection.Mount} returns this
 */
proto.ppconnection.Mount.prototype.setEnclosuretype = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string enclosureId = 6;
 * @return {string}
 */
proto.ppconnection.Mount.prototype.getEnclosureid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Mount} returns this
 */
proto.ppconnection.Mount.prototype.setEnclosureid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Device.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Device.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Device} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Device.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    deviceeui: jspb.Message.getFieldWithDefault(msg, 2, ""),
    loradetails: (f = msg.getLoradetails()) && proto.ppconnection.LoRaDetails.toObject(includeInstance, f),
    macaddress: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modeltype: jspb.Message.getFieldWithDefault(msg, 5, 0),
    mqttconnectionstring: jspb.Message.getFieldWithDefault(msg, 6, ""),
    serialnumber: jspb.Message.getFieldWithDefault(msg, 7, 0),
    ppnumber: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Device}
 */
proto.ppconnection.Device.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Device;
  return proto.ppconnection.Device.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Device} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Device}
 */
proto.ppconnection.Device.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceeui(value);
      break;
    case 3:
      var value = new proto.ppconnection.LoRaDetails;
      reader.readMessage(value,proto.ppconnection.LoRaDetails.deserializeBinaryFromReader);
      msg.setLoradetails(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMacaddress(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setModeltype(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMqttconnectionstring(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSerialnumber(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPpnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Device.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Device.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Device} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Device.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getDeviceeui();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLoradetails();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ppconnection.LoRaDetails.serializeBinaryToWriter
    );
  }
  f = message.getMacaddress();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModeltype();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getMqttconnectionstring();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSerialnumber();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPpnumber();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.ppconnection.Device.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string deviceEUI = 2;
 * @return {string}
 */
proto.ppconnection.Device.prototype.getDeviceeui = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setDeviceeui = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional LoRaDetails loRaDetails = 3;
 * @return {?proto.ppconnection.LoRaDetails}
 */
proto.ppconnection.Device.prototype.getLoradetails = function() {
  return /** @type{?proto.ppconnection.LoRaDetails} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.LoRaDetails, 3));
};


/**
 * @param {?proto.ppconnection.LoRaDetails|undefined} value
 * @return {!proto.ppconnection.Device} returns this
*/
proto.ppconnection.Device.prototype.setLoradetails = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.clearLoradetails = function() {
  return this.setLoradetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.Device.prototype.hasLoradetails = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string macAddress = 4;
 * @return {string}
 */
proto.ppconnection.Device.prototype.getMacaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setMacaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 modelType = 5;
 * @return {number}
 */
proto.ppconnection.Device.prototype.getModeltype = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setModeltype = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string mqttConnectionString = 6;
 * @return {string}
 */
proto.ppconnection.Device.prototype.getMqttconnectionstring = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setMqttconnectionstring = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 serialNumber = 7;
 * @return {number}
 */
proto.ppconnection.Device.prototype.getSerialnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setSerialnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string ppNumber = 8;
 * @return {string}
 */
proto.ppconnection.Device.prototype.getPpnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Device} returns this
 */
proto.ppconnection.Device.prototype.setPpnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.LoRaDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.LoRaDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.LoRaDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.LoRaDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    applicationeui: jspb.Message.getFieldWithDefault(msg, 1, ""),
    applicationsoftwarekey: jspb.Message.getFieldWithDefault(msg, 2, ""),
    deviceaddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
    networksoftwarekey: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.LoRaDetails}
 */
proto.ppconnection.LoRaDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.LoRaDetails;
  return proto.ppconnection.LoRaDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.LoRaDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.LoRaDetails}
 */
proto.ppconnection.LoRaDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationeui(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setApplicationsoftwarekey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceaddress(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworksoftwarekey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.LoRaDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.LoRaDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.LoRaDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.LoRaDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplicationeui();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getApplicationsoftwarekey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDeviceaddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNetworksoftwarekey();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string applicationEUI = 1;
 * @return {string}
 */
proto.ppconnection.LoRaDetails.prototype.getApplicationeui = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.LoRaDetails} returns this
 */
proto.ppconnection.LoRaDetails.prototype.setApplicationeui = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string applicationSoftwareKey = 2;
 * @return {string}
 */
proto.ppconnection.LoRaDetails.prototype.getApplicationsoftwarekey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.LoRaDetails} returns this
 */
proto.ppconnection.LoRaDetails.prototype.setApplicationsoftwarekey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string deviceAddress = 3;
 * @return {string}
 */
proto.ppconnection.LoRaDetails.prototype.getDeviceaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.LoRaDetails} returns this
 */
proto.ppconnection.LoRaDetails.prototype.setDeviceaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string networkSoftwareKey = 4;
 * @return {string}
 */
proto.ppconnection.LoRaDetails.prototype.getNetworksoftwarekey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.LoRaDetails} returns this
 */
proto.ppconnection.LoRaDetails.prototype.setNetworksoftwarekey = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.GetConnectionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.GetConnectionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.GetConnectionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetConnectionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    active: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.GetConnectionsRequest}
 */
proto.ppconnection.GetConnectionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.GetConnectionsRequest;
  return proto.ppconnection.GetConnectionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.GetConnectionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.GetConnectionsRequest}
 */
proto.ppconnection.GetConnectionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.GetConnectionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.GetConnectionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.GetConnectionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetConnectionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool active = 1;
 * @return {boolean}
 */
proto.ppconnection.GetConnectionsRequest.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ppconnection.GetConnectionsRequest} returns this
 */
proto.ppconnection.GetConnectionsRequest.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.GetConnectionsByTransformerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.GetConnectionsByTransformerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.GetConnectionsByTransformerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetConnectionsByTransformerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transformernumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.GetConnectionsByTransformerRequest}
 */
proto.ppconnection.GetConnectionsByTransformerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.GetConnectionsByTransformerRequest;
  return proto.ppconnection.GetConnectionsByTransformerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.GetConnectionsByTransformerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.GetConnectionsByTransformerRequest}
 */
proto.ppconnection.GetConnectionsByTransformerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransformernumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.GetConnectionsByTransformerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.GetConnectionsByTransformerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.GetConnectionsByTransformerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetConnectionsByTransformerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransformernumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string transformerNumber = 1;
 * @return {string}
 */
proto.ppconnection.GetConnectionsByTransformerRequest.prototype.getTransformernumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.GetConnectionsByTransformerRequest} returns this
 */
proto.ppconnection.GetConnectionsByTransformerRequest.prototype.setTransformernumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Identifier.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Identifier.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Identifier} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Identifier.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Identifier}
 */
proto.ppconnection.Identifier.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Identifier;
  return proto.ppconnection.Identifier.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Identifier} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Identifier}
 */
proto.ppconnection.Identifier.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Identifier.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Identifier.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Identifier} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Identifier.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.Identifier.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Identifier} returns this
 */
proto.ppconnection.Identifier.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ppconnection.Identifiers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Identifiers.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Identifiers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Identifiers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Identifiers.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifiersList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Identifiers}
 */
proto.ppconnection.Identifiers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Identifiers;
  return proto.ppconnection.Identifiers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Identifiers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Identifiers}
 */
proto.ppconnection.Identifiers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addIdentifiers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Identifiers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Identifiers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Identifiers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Identifiers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifiersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string identifiers = 1;
 * @return {!Array<string>}
 */
proto.ppconnection.Identifiers.prototype.getIdentifiersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ppconnection.Identifiers} returns this
 */
proto.ppconnection.Identifiers.prototype.setIdentifiersList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ppconnection.Identifiers} returns this
 */
proto.ppconnection.Identifiers.prototype.addIdentifiers = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ppconnection.Identifiers} returns this
 */
proto.ppconnection.Identifiers.prototype.clearIdentifiersList = function() {
  return this.setIdentifiersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.CreateConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.CreateConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.CreateConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.CreateConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    oldidentifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connection: (f = msg.getConnection()) && proto.ppconnection.Connection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.CreateConnectionRequest}
 */
proto.ppconnection.CreateConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.CreateConnectionRequest;
  return proto.ppconnection.CreateConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.CreateConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.CreateConnectionRequest}
 */
proto.ppconnection.CreateConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldidentifier(value);
      break;
    case 2:
      var value = new proto.ppconnection.Connection;
      reader.readMessage(value,proto.ppconnection.Connection.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.CreateConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.CreateConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.CreateConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.CreateConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOldidentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Connection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string oldIdentifier = 1;
 * @return {string}
 */
proto.ppconnection.CreateConnectionRequest.prototype.getOldidentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.CreateConnectionRequest} returns this
 */
proto.ppconnection.CreateConnectionRequest.prototype.setOldidentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Connection connection = 2;
 * @return {?proto.ppconnection.Connection}
 */
proto.ppconnection.CreateConnectionRequest.prototype.getConnection = function() {
  return /** @type{?proto.ppconnection.Connection} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Connection, 2));
};


/**
 * @param {?proto.ppconnection.Connection|undefined} value
 * @return {!proto.ppconnection.CreateConnectionRequest} returns this
*/
proto.ppconnection.CreateConnectionRequest.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.CreateConnectionRequest} returns this
 */
proto.ppconnection.CreateConnectionRequest.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.CreateConnectionRequest.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.UpdateConnectionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.UpdateConnectionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.UpdateConnectionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateConnectionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connection: (f = msg.getConnection()) && proto.ppconnection.Connection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.UpdateConnectionRequest}
 */
proto.ppconnection.UpdateConnectionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.UpdateConnectionRequest;
  return proto.ppconnection.UpdateConnectionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.UpdateConnectionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.UpdateConnectionRequest}
 */
proto.ppconnection.UpdateConnectionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = new proto.ppconnection.Connection;
      reader.readMessage(value,proto.ppconnection.Connection.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.UpdateConnectionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.UpdateConnectionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.UpdateConnectionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateConnectionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Connection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.UpdateConnectionRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.UpdateConnectionRequest} returns this
 */
proto.ppconnection.UpdateConnectionRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Connection connection = 2;
 * @return {?proto.ppconnection.Connection}
 */
proto.ppconnection.UpdateConnectionRequest.prototype.getConnection = function() {
  return /** @type{?proto.ppconnection.Connection} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Connection, 2));
};


/**
 * @param {?proto.ppconnection.Connection|undefined} value
 * @return {!proto.ppconnection.UpdateConnectionRequest} returns this
*/
proto.ppconnection.UpdateConnectionRequest.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.UpdateConnectionRequest} returns this
 */
proto.ppconnection.UpdateConnectionRequest.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.UpdateConnectionRequest.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.UpdateMountRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.UpdateMountRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.UpdateMountRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateMountRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mount: (f = msg.getMount()) && proto.ppconnection.Mount.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.UpdateMountRequest}
 */
proto.ppconnection.UpdateMountRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.UpdateMountRequest;
  return proto.ppconnection.UpdateMountRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.UpdateMountRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.UpdateMountRequest}
 */
proto.ppconnection.UpdateMountRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = new proto.ppconnection.Mount;
      reader.readMessage(value,proto.ppconnection.Mount.deserializeBinaryFromReader);
      msg.setMount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.UpdateMountRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.UpdateMountRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.UpdateMountRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateMountRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Mount.serializeBinaryToWriter
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.UpdateMountRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.UpdateMountRequest} returns this
 */
proto.ppconnection.UpdateMountRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Mount mount = 2;
 * @return {?proto.ppconnection.Mount}
 */
proto.ppconnection.UpdateMountRequest.prototype.getMount = function() {
  return /** @type{?proto.ppconnection.Mount} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Mount, 2));
};


/**
 * @param {?proto.ppconnection.Mount|undefined} value
 * @return {!proto.ppconnection.UpdateMountRequest} returns this
*/
proto.ppconnection.UpdateMountRequest.prototype.setMount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.UpdateMountRequest} returns this
 */
proto.ppconnection.UpdateMountRequest.prototype.clearMount = function() {
  return this.setMount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.UpdateMountRequest.prototype.hasMount = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.UpdateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.UpdateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.UpdateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    device: (f = msg.getDevice()) && proto.ppconnection.Device.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.UpdateDeviceRequest}
 */
proto.ppconnection.UpdateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.UpdateDeviceRequest;
  return proto.ppconnection.UpdateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.UpdateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.UpdateDeviceRequest}
 */
proto.ppconnection.UpdateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = new proto.ppconnection.Device;
      reader.readMessage(value,proto.ppconnection.Device.deserializeBinaryFromReader);
      msg.setDevice(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.UpdateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.UpdateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.UpdateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDevice();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Device.serializeBinaryToWriter
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.UpdateDeviceRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.UpdateDeviceRequest} returns this
 */
proto.ppconnection.UpdateDeviceRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Device device = 2;
 * @return {?proto.ppconnection.Device}
 */
proto.ppconnection.UpdateDeviceRequest.prototype.getDevice = function() {
  return /** @type{?proto.ppconnection.Device} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Device, 2));
};


/**
 * @param {?proto.ppconnection.Device|undefined} value
 * @return {!proto.ppconnection.UpdateDeviceRequest} returns this
*/
proto.ppconnection.UpdateDeviceRequest.prototype.setDevice = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.UpdateDeviceRequest} returns this
 */
proto.ppconnection.UpdateDeviceRequest.prototype.clearDevice = function() {
  return this.setDevice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.UpdateDeviceRequest.prototype.hasDevice = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.UpdateConnectionStateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.UpdateConnectionStateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.UpdateConnectionStateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateConnectionStateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connectionstate: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.UpdateConnectionStateRequest}
 */
proto.ppconnection.UpdateConnectionStateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.UpdateConnectionStateRequest;
  return proto.ppconnection.UpdateConnectionStateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.UpdateConnectionStateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.UpdateConnectionStateRequest}
 */
proto.ppconnection.UpdateConnectionStateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {!proto.ppconnection.Connection.ConnectionState} */ (reader.readEnum());
      msg.setConnectionstate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.UpdateConnectionStateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.UpdateConnectionStateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.UpdateConnectionStateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateConnectionStateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnectionstate();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.UpdateConnectionStateRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.UpdateConnectionStateRequest} returns this
 */
proto.ppconnection.UpdateConnectionStateRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Connection.ConnectionState connectionState = 2;
 * @return {!proto.ppconnection.Connection.ConnectionState}
 */
proto.ppconnection.UpdateConnectionStateRequest.prototype.getConnectionstate = function() {
  return /** @type {!proto.ppconnection.Connection.ConnectionState} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ppconnection.Connection.ConnectionState} value
 * @return {!proto.ppconnection.UpdateConnectionStateRequest} returns this
 */
proto.ppconnection.UpdateConnectionStateRequest.prototype.setConnectionstate = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.UpdateJobRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.UpdateJobRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.UpdateJobRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateJobRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    job: (f = msg.getJob()) && proto.ppconnection.Job.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.UpdateJobRequest}
 */
proto.ppconnection.UpdateJobRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.UpdateJobRequest;
  return proto.ppconnection.UpdateJobRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.UpdateJobRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.UpdateJobRequest}
 */
proto.ppconnection.UpdateJobRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = new proto.ppconnection.Job;
      reader.readMessage(value,proto.ppconnection.Job.deserializeBinaryFromReader);
      msg.setJob(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.UpdateJobRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.UpdateJobRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.UpdateJobRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateJobRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getJob();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Job.serializeBinaryToWriter
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.UpdateJobRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.UpdateJobRequest} returns this
 */
proto.ppconnection.UpdateJobRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Job job = 2;
 * @return {?proto.ppconnection.Job}
 */
proto.ppconnection.UpdateJobRequest.prototype.getJob = function() {
  return /** @type{?proto.ppconnection.Job} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Job, 2));
};


/**
 * @param {?proto.ppconnection.Job|undefined} value
 * @return {!proto.ppconnection.UpdateJobRequest} returns this
*/
proto.ppconnection.UpdateJobRequest.prototype.setJob = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.UpdateJobRequest} returns this
 */
proto.ppconnection.UpdateJobRequest.prototype.clearJob = function() {
  return this.setJob(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.UpdateJobRequest.prototype.hasJob = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.UpdateLinesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.UpdateLinesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.UpdateLinesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateLinesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    line1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    line2: jspb.Message.getFieldWithDefault(msg, 3, 0),
    line3: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.UpdateLinesRequest}
 */
proto.ppconnection.UpdateLinesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.UpdateLinesRequest;
  return proto.ppconnection.UpdateLinesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.UpdateLinesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.UpdateLinesRequest}
 */
proto.ppconnection.UpdateLinesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLine3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.UpdateLinesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.UpdateLinesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.UpdateLinesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.UpdateLinesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLine1();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLine2();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getLine3();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.UpdateLinesRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.UpdateLinesRequest} returns this
 */
proto.ppconnection.UpdateLinesRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 line1 = 2;
 * @return {number}
 */
proto.ppconnection.UpdateLinesRequest.prototype.getLine1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.UpdateLinesRequest} returns this
 */
proto.ppconnection.UpdateLinesRequest.prototype.setLine1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 line2 = 3;
 * @return {number}
 */
proto.ppconnection.UpdateLinesRequest.prototype.getLine2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.UpdateLinesRequest} returns this
 */
proto.ppconnection.UpdateLinesRequest.prototype.setLine2 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 line3 = 4;
 * @return {number}
 */
proto.ppconnection.UpdateLinesRequest.prototype.getLine3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.UpdateLinesRequest} returns this
 */
proto.ppconnection.UpdateLinesRequest.prototype.setLine3 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.GetAddressRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.GetAddressRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.GetAddressRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetAddressRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    installnumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.GetAddressRequest}
 */
proto.ppconnection.GetAddressRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.GetAddressRequest;
  return proto.ppconnection.GetAddressRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.GetAddressRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.GetAddressRequest}
 */
proto.ppconnection.GetAddressRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInstallnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.GetAddressRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.GetAddressRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.GetAddressRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetAddressRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInstallnumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string installNumber = 1;
 * @return {string}
 */
proto.ppconnection.GetAddressRequest.prototype.getInstallnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.GetAddressRequest} returns this
 */
proto.ppconnection.GetAddressRequest.prototype.setInstallnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.GetDeviceBySerialNumberRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.GetDeviceBySerialNumberRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serialnumber: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.GetDeviceBySerialNumberRequest}
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.GetDeviceBySerialNumberRequest;
  return proto.ppconnection.GetDeviceBySerialNumberRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.GetDeviceBySerialNumberRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.GetDeviceBySerialNumberRequest}
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSerialnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.GetDeviceBySerialNumberRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.GetDeviceBySerialNumberRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSerialnumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string serialNumber = 1;
 * @return {string}
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.prototype.getSerialnumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.GetDeviceBySerialNumberRequest} returns this
 */
proto.ppconnection.GetDeviceBySerialNumberRequest.prototype.setSerialnumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    reply: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Response}
 */
proto.ppconnection.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Response;
  return proto.ppconnection.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Response}
 */
proto.ppconnection.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReply(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReply();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string reply = 1;
 * @return {string}
 */
proto.ppconnection.Response.prototype.getReply = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Response} returns this
 */
proto.ppconnection.Response.prototype.setReply = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Address.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Address.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Address} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Address.toObject = function(includeInstance, msg) {
  var f, obj = {
    streetaddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
    town: jspb.Message.getFieldWithDefault(msg, 2, ""),
    country: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Address}
 */
proto.ppconnection.Address.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Address;
  return proto.ppconnection.Address.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Address} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Address}
 */
proto.ppconnection.Address.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStreetaddress(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTown(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCountry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Address.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Address.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Address} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Address.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStreetaddress();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTown();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCountry();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string streetAddress = 1;
 * @return {string}
 */
proto.ppconnection.Address.prototype.getStreetaddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Address} returns this
 */
proto.ppconnection.Address.prototype.setStreetaddress = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string town = 2;
 * @return {string}
 */
proto.ppconnection.Address.prototype.getTown = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Address} returns this
 */
proto.ppconnection.Address.prototype.setTown = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string country = 3;
 * @return {string}
 */
proto.ppconnection.Address.prototype.getCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Address} returns this
 */
proto.ppconnection.Address.prototype.setCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.LatLng.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.LatLng.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.LatLng} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.LatLng.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lng: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.LatLng}
 */
proto.ppconnection.LatLng.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.LatLng;
  return proto.ppconnection.LatLng.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.LatLng} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.LatLng}
 */
proto.ppconnection.LatLng.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLng(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.LatLng.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.LatLng.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.LatLng} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.LatLng.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLng();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
};


/**
 * optional float lat = 1;
 * @return {number}
 */
proto.ppconnection.LatLng.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.LatLng} returns this
 */
proto.ppconnection.LatLng.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float lng = 2;
 * @return {number}
 */
proto.ppconnection.LatLng.prototype.getLng = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.LatLng} returns this
 */
proto.ppconnection.LatLng.prototype.setLng = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Empty}
 */
proto.ppconnection.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Empty;
  return proto.ppconnection.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Empty}
 */
proto.ppconnection.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ppconnection.TransformerList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.TransformerList.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.TransformerList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.TransformerList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.TransformerList.toObject = function(includeInstance, msg) {
  var f, obj = {
    transformersList: jspb.Message.toObjectList(msg.getTransformersList(),
    proto.ppconnection.Transformer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.TransformerList}
 */
proto.ppconnection.TransformerList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.TransformerList;
  return proto.ppconnection.TransformerList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.TransformerList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.TransformerList}
 */
proto.ppconnection.TransformerList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ppconnection.Transformer;
      reader.readMessage(value,proto.ppconnection.Transformer.deserializeBinaryFromReader);
      msg.addTransformers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.TransformerList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.TransformerList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.TransformerList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.TransformerList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransformersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ppconnection.Transformer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transformer transformers = 1;
 * @return {!Array<!proto.ppconnection.Transformer>}
 */
proto.ppconnection.TransformerList.prototype.getTransformersList = function() {
  return /** @type{!Array<!proto.ppconnection.Transformer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ppconnection.Transformer, 1));
};


/**
 * @param {!Array<!proto.ppconnection.Transformer>} value
 * @return {!proto.ppconnection.TransformerList} returns this
*/
proto.ppconnection.TransformerList.prototype.setTransformersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ppconnection.Transformer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ppconnection.Transformer}
 */
proto.ppconnection.TransformerList.prototype.addTransformers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ppconnection.Transformer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ppconnection.TransformerList} returns this
 */
proto.ppconnection.TransformerList.prototype.clearTransformersList = function() {
  return this.setTransformersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.Transformer.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.Transformer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.Transformer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Transformer.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.Transformer}
 */
proto.ppconnection.Transformer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.Transformer;
  return proto.ppconnection.Transformer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.Transformer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.Transformer}
 */
proto.ppconnection.Transformer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.Transformer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.Transformer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.Transformer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.Transformer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.ppconnection.Transformer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.Transformer} returns this
 */
proto.ppconnection.Transformer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.ConnectionImage.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.ConnectionImage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.ConnectionImage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.ConnectionImage.toObject = function(includeInstance, msg) {
  var f, obj = {
    connectionid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    value: jspb.Message.getFieldWithDefault(msg, 2, ""),
    datetime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    orientation: jspb.Message.getFieldWithDefault(msg, 4, 0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    altitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.ConnectionImage}
 */
proto.ppconnection.ConnectionImage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.ConnectionImage;
  return proto.ppconnection.ConnectionImage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.ConnectionImage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.ConnectionImage}
 */
proto.ppconnection.ConnectionImage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConnectionid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatetime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrientation(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLatitude(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLongitude(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setAltitude(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.ConnectionImage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.ConnectionImage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.ConnectionImage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.ConnectionImage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConnectionid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDatetime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrientation();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getAltitude();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
};


/**
 * optional string connectionId = 1;
 * @return {string}
 */
proto.ppconnection.ConnectionImage.prototype.getConnectionid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setConnectionid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.ppconnection.ConnectionImage.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string dateTime = 3;
 * @return {string}
 */
proto.ppconnection.ConnectionImage.prototype.getDatetime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setDatetime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 orientation = 4;
 * @return {number}
 */
proto.ppconnection.ConnectionImage.prototype.getOrientation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setOrientation = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional float latitude = 5;
 * @return {number}
 */
proto.ppconnection.ConnectionImage.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float longitude = 6;
 * @return {number}
 */
proto.ppconnection.ConnectionImage.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float altitude = 7;
 * @return {number}
 */
proto.ppconnection.ConnectionImage.prototype.getAltitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.ConnectionImage} returns this
 */
proto.ppconnection.ConnectionImage.prototype.setAltitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.AddSlotRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.AddSlotRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.AddSlotRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.AddSlotRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    identifier: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slot: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.AddSlotRequest}
 */
proto.ppconnection.AddSlotRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.AddSlotRequest;
  return proto.ppconnection.AddSlotRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.AddSlotRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.AddSlotRequest}
 */
proto.ppconnection.AddSlotRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdentifier(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSlot(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.AddSlotRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.AddSlotRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.AddSlotRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.AddSlotRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdentifier();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlot();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string identifier = 1;
 * @return {string}
 */
proto.ppconnection.AddSlotRequest.prototype.getIdentifier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.AddSlotRequest} returns this
 */
proto.ppconnection.AddSlotRequest.prototype.setIdentifier = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 slot = 2;
 * @return {number}
 */
proto.ppconnection.AddSlotRequest.prototype.getSlot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ppconnection.AddSlotRequest} returns this
 */
proto.ppconnection.AddSlotRequest.prototype.setSlot = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.ppconnection.ConnectionUpdateMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ppconnection.ConnectionUpdateMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.ConnectionUpdateMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    connection: (f = msg.getConnection()) && proto.ppconnection.Connection.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ppconnection.ConnectionUpdateMessage}
 */
proto.ppconnection.ConnectionUpdateMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ppconnection.ConnectionUpdateMessage;
  return proto.ppconnection.ConnectionUpdateMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ppconnection.ConnectionUpdateMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ppconnection.ConnectionUpdateMessage}
 */
proto.ppconnection.ConnectionUpdateMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.ppconnection.Connection;
      reader.readMessage(value,proto.ppconnection.Connection.deserializeBinaryFromReader);
      msg.setConnection(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ppconnection.ConnectionUpdateMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ppconnection.ConnectionUpdateMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ppconnection.ConnectionUpdateMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConnection();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ppconnection.Connection.serializeBinaryToWriter
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ppconnection.ConnectionUpdateMessage} returns this
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Connection connection = 2;
 * @return {?proto.ppconnection.Connection}
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.getConnection = function() {
  return /** @type{?proto.ppconnection.Connection} */ (
    jspb.Message.getWrapperField(this, proto.ppconnection.Connection, 2));
};


/**
 * @param {?proto.ppconnection.Connection|undefined} value
 * @return {!proto.ppconnection.ConnectionUpdateMessage} returns this
*/
proto.ppconnection.ConnectionUpdateMessage.prototype.setConnection = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ppconnection.ConnectionUpdateMessage} returns this
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.clearConnection = function() {
  return this.setConnection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ppconnection.ConnectionUpdateMessage.prototype.hasConnection = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.ppconnection);
