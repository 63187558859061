import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import configAPI from '../../scripts/configAPI';
// import authAPI from '../../scripts/authAPI';
// import { setToken } from './authSlice';
import keycloak from '../../keycloak';

const getToken = async (thunkAPI) => {    
  if (keycloak) {
      return keycloak.token;
  }
  
  return null;
  /*
  let state = thunkAPI.getState();
  const refreshToken = await authAPI.refreshToken(state.auth.token, state.auth.username, state.auth.password);
  thunkAPI.dispatch(setToken(refreshToken));

  return refreshToken;
  */
}

export const getConfigField = createAsyncThunk(
  'config/getConfigField',
  async (args, thunkAPI) => {
      try {
          let token = await getToken(thunkAPI);
          const response = await configAPI.getConfigField(args.deviceEUI, args.slot, args.fieldName, token);
          return response;
      } catch (err) {
          throw new Error(err.response && err.response.data ? err.response.data : err.message);
      }
  }
);

export const getAllConfig = createAsyncThunk(
  'config/getAllConfig',
  async (args, thunkAPI) => {
      try {
          let token = await getToken(thunkAPI);
          const response = await configAPI.getAllConfig(args.identifier, 0, token);
          return response;
      } catch (err) {
          throw new Error(err.response && err.response.data ? err.response.data : err.message);
      }
  }
);

export const getAllS11Config = createAsyncThunk(
  'config/getAllS11Config',
  async (args, thunkAPI) => {
      try {
          let token = await getToken(thunkAPI);
          const response = await configAPI.getAllConfig(args.identifier, 100, token);
          return response;
      } catch (err) {
          throw new Error(err.response && err.response.data ? err.response.data : err.message);
      }
  }
);

export const setConfigField = createAsyncThunk(
  'config/setConfigField',
  async (args, thunkAPI) => {
      try {
          let token = await getToken(thunkAPI);
          const response = await configAPI.setConfigField(args.deviceEUI, args.slot, args.fieldName, args.value, token);
          return response;
      } catch (err) {
          throw new Error(err.response && err.response.data ? err.response.data : err.message);
      }
  }
);

const initialState = {
  loading: false,
  error: null,
  configField: null,
  configFields: [],
  s11ConfigFields: [],
  response: null,
};

const configSlice = createSlice({
  name: 'config',
  initialState: initialState,
  reducers: {
    clearError(state, action) {
      state.error = null;
  },
  },
  extraReducers: {
    [getConfigField.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      state.configField = null;
    },
    [getConfigField.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getConfigField.fulfilled]: (state, action) => {
      state.loading = false;
      state.configField = action.payload;
    },
    [getAllConfig.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      state.configFields = [];
    },
    [getAllConfig.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getAllConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.configFields = action.payload;
    },
    [getAllS11Config.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      state.s11ConfigFields = [];
    },
    [getAllS11Config.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [getAllS11Config.fulfilled]: (state, action) => {
      state.loading = false;
      state.s11ConfigFields = action.payload;
    },
    [setConfigField.pending]: (state, action) => {
      state.error = null;
      state.loading = true;
      state.response = null;
    },
    [setConfigField.rejected]: (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    },
    [setConfigField.fulfilled]: (state, action) => {
      state.loading = false;
      state.response = action.payload;
    },
  }
});

export const { 
  clearError
} = configSlice.actions;

export default configSlice.reducer;