import axios from "axios";


export function getMetadata(token) {
    var deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 5);
    var metadata = { 'authorization': token, 'deadline': deadline.getTime() };
    return metadata;
}

export function getErrorMessage(err) {
  if (err.response) {
    return (
      err.response.status +
      " " +
      err.response.statusText +
      ": " +
      err.response.data
    );
  } else if (err.message) {
    return err.message;
  } else {
    return JSON.stringify(err);
  }
}

export function getTenant() {
    //const host = window.location.hostname;
    let tenant = process.env.REACT_APP_TENANT;
    if (tenant === undefined || tenant === null) {
      console.log('TENANT not defined, using default one');
     
      //old code, just in case, as a backup
      const host = window.location.hostname;
      if (host === "localhost") {
        return "stagepower";
      }
      tenant = window.location.host.split('.')[1];
      if (!isNaN(tenant)) { //may 168 if accessing local server from mobile
        return "stagepower";
      }
    }
    return tenant;
}

function getTableName(tenant, table) {
  return table;
  /*if (tenant === "westpower") {
    return table;
  }
  return tenant + "_" + table;*/
}

export function getConnectionServiceUrl() {
  let env_url = process.env.REACT_APP_CONNECTION_SERVICE_URL;
  if (isNaN(env_url)) {
    const tenant = getTenant();
    return `https://connection.${tenant}.powerpilot.nz`;
  }
  else
    return env_url;
}

export function getConfigServiceUrl() {
  let env_url = process.env.REACT_APP_CONFIG_SERVICE_URL;
  if (isNaN(env_url)) {
    const tenant = getTenant();
    return `https://config.${tenant}.powerpilot.nz`;
  }
  else
    return env_url;
}

export function getFunctionServiceUrl() {
  let env_url = process.env.REACT_APP_FUNCTION_SERVICE_URL;
  if (isNaN(env_url)) {
    const tenant = getTenant();
    return `https://function.${tenant}.powerpilot.nz`;
  }
  else
    return env_url;
}

export function getSwitchingServiceURL() {
  const tenant = getTenant();
  return `https://switching.${tenant}.powerpilot.nz`;
}

export function getMqttHost() {
    const tenant = getTenant();
    return `mqtt.${tenant}.powerpilot.nz`;
}

export function getKeycloakRealm() {
  const tenant = getTenant();
  return tenant;
}

// export async function getVersion() {
//   const tenant = getTenant();
//   const url = `https://admin.${tenant}.powerpilot.nz/version`;
//   const response = await axios.get(url);
//   return response.data;
// }
export async function getVersion() {
  let env_url = process.env.REACT_APP_VERSION_URL;
  //const response =  none
  if (isNaN(env_url)) {
    const tenant = getTenant();
    env_url = `https://admin.${tenant}.powerpilot.nz/version`;
    //return await axios.get(url);
  }
  const response = await axios.get(env_url);
  return response.data;
}

export function getAuthServerUrl() {
  let env_url = process.env.REACT_APP_AUTHSERVER_SERVICE_URL;
  if (isNaN(env_url)) {
    const tenant = getTenant();
    return `https://auth.${tenant}.powerpilot.nz/auth`;
  }
  else
    return env_url;
}

export function getAuthServiceUrl() {
  let env_url = process.env.REACT_APP_AUTH_SERVICE_URL;
  if (isNaN(env_url)) {
    const tenant = getTenant();
    return `https://auth.${tenant}.powerpilot.nz`;
  }
  else
    return env_url;
}

export function getDataServiceUrl() {
  let env_url = process.env.REACT_APP_DATA_SERVICE_URL;
  if (isNaN(env_url)) {
    const tenant = getTenant();
    return `https://data.${tenant}.powerpilot.nz`;
  }
  else
    return env_url;
}


export function getRealtimeGetUrl(topic, deviceeui, phase) {
  const tenant = getTenant();
  let url = `https://realtime.${tenant}.powerpilot.nz/api/table/${tenant}_${topic}/${deviceeui}`;

  if (phase) {
    url += `/${phase}`;
  }
  return url;
}

export function getRealtimeSocketIOUrl(topic, deviceeui, phase) {
  const tenant = getTenant();
  let url = `https://realtime.${tenant}.powerpilot.nz/api/${tenant}_${topic}/${deviceeui}`;
  
  if (phase) {
    url += `/${phase}`;
  }
  return url;
}
